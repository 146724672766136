/* eslint-disable complexity, max-statements */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import loadable from "@loadable/component";
import FindDreamCarsV2 from "../find-dream-cars-v2";
import { appUrl } from "../../../constants/url-constants";
import { NUMBER, WINDOW_SIZE_LIMIT, CTA_WITHOUT_PINCODE_PAGES } from "../../../constants/app-constants";
import { parseServicesList, getTransformedServiceLabel, disableActiveTabs } from "../../../utils/helpers/parse-services-list";
import { MEDIA_DEVICE_TYPES, SERVICE_TAB_LABEL } from "../../../constants/super-app-constants";
import MoreIcon from "./images/more.svg";
import AllServices from "../all-services";
import SuperLogoCard from "../../shared/super-logo-card";
import { Button } from "@cars24/turbo-web/lib/button";
import { preventPropagation } from "../../../utils/helpers/prevent-propagation";
import Filters from "../../../utils/filters-v3";
import { LISTING_PAGE_LANDING_SOURCE } from "../car-list/constants";
import { LOCATION_PAGE_TYPE } from "../location-picker-popup/constant";
import { CMS_ACTION_TYPES } from "../../../constants/cms-constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { sendCustomGaEvents } from "../../../tracking";
import { businessClicked } from "../../../constants/tracking-constants/super-home-events";
import GenericBrandBanner from "../../shared/generic-brand-banner";
const AllServicesPopup = loadable(() => import("../all-services-popup"));

const SuperWebServicesSection = ({

    superApp,
    sectionKey,
    popularBrands,
    appliedOptions,
    pincode,
    isCTAClickedWithoutPincode,
    cities,
    history,
    showAnimation,
    platformLabel,
    statsigDynamicConfigData,
    websiteAdvertisementBannerData,
    onClickCustomHandler = () => { },
    onClickViewAllCars = () => { },
    redirectToListingPageByQuery = () => { },
    toggleLocationPickerConnect = () => { },
    locationPageTypeConnect = () => { },
    setIsCTAClickedWithoutPincodeConnect = () => { },
    reloadCarListConnect = () => { }
}) => {

    const {  websiteAds  } = statsigDynamicConfigData || {};
    const bannerType = "970x90";

    const [isSmallDesktop, setIsSmallDesktop] = useState(true);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [isServicesLoading, setIsServicesLoading] = useState(true);
    const { selectedCity } = cities || {};
    const { id: selectedCityId } = selectedCity || {};
    const { parsedData } = superApp || {};
    const { title: sectionTitle, data: sectionData } = parsedData[sectionKey] || {};
    const { content: servicesContent = [] } = sectionData || {};
    const { parsedServiceList, serviceDataMap } = parseServicesList({
        serviceList: servicesContent,
        deviceType: MEDIA_DEVICE_TYPES.DESKTOP
    });

    const listLimit = isSmallDesktop ? NUMBER.EIGHT : NUMBER.TWELVE;
    const popularBrandsList = useMemo(() => {
        let list = [...popularBrands];
        if (list.length > listLimit) {
            list = [...list.slice(NUMBER.ZERO, listLimit)];
        }
        return list;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popularBrands, listLimit, isSmallDesktop]);
    const [modalServiceList, setModalServiceList] = useState(null);

    useEffect(() => {
        if (window) {
            if (window.innerWidth >= WINDOW_SIZE_LIMIT) {
                setIsSmallDesktop(false);
            } else {
                setIsSmallDesktop(true);
            }
            setIsServicesLoading(false);
        }
    }, []);

    const handleClick = (brand, e) => {
        if (!pincode) {
            toggleLocationPickerConnect(true);
            locationPageTypeConnect(LOCATION_PAGE_TYPE.homePage);
            setIsCTAClickedWithoutPincodeConnect({
                type: CTA_WITHOUT_PINCODE_PAGES.HOMEPAGE_BRAND_SELECTION
            });
            return;
        }
        const { query } = brand || {};
        if (e) preventPropagation(e);
        const appliedFilters = new Filters({ cityID: selectedCityId, pincode });
        const listingUrl = appliedFilters.getListingUrl({
            ...(query && { filters: query }),
            options: {
                ...appliedOptions,
                listingSource: LISTING_PAGE_LANDING_SOURCE.Homepage_Filters
            }
        }).relativeURL;
        reloadCarListConnect(true);
        history.push(listingUrl);

    };

    useEffect(() => {
        if (isCTAClickedWithoutPincode && isCTAClickedWithoutPincode.type === CTA_WITHOUT_PINCODE_PAGES.HOMEPAGE_BRAND_SELECTION && pincode) {
            handleClick(selectedBrand);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCityId && selectedCityId.toString()]);

    const onClickServiceTab = async (label, idx) => {
        const tabLabel = getTransformedServiceLabel(label);
        if (label === SERVICE_TAB_LABEL.MORE) {
            setModalServiceList(disableActiveTabs(parsedServiceList));
        } else if (tabLabel && serviceDataMap[tabLabel]) {
            const { action } = serviceDataMap[tabLabel] || {};
            const { actioType } = (action || {})[MEDIA_DEVICE_TYPES.MSITE] || {};
            if (actioType !== CMS_ACTION_TYPES.REDIRECT) {
                onClickCustomHandler(serviceDataMap[tabLabel]);
            }
        }

        await yieldToMain();
        sendCustomGaEvents({
            ...businessClicked,
            eventLabel: `${(label || "").split(" ").join("_")} | ${modalServiceList && modalServiceList.length ? "More_page" : "Super_home_page"} | ${platformLabel} | bu_position_${idx + 1}`
        });
    };

    const getServicesData = () => {

        let filteredDisplayList = [...parsedServiceList];

        if (filteredDisplayList.length > listLimit) {
            filteredDisplayList = [...filteredDisplayList.slice(NUMBER.ZERO, listLimit - NUMBER.ONE), {
                iconUrl: MoreIcon,
                label: "More",
                onClickHandler: () => {
                    setModalServiceList(disableActiveTabs(parsedServiceList));
                }
            }];
        }

        return filteredDisplayList || [];
    };

    const onCloseServiceModal = () => {
        setModalServiceList(null);
    };

    const handleBrandSelect = (brand, e) => {
        handleClick(brand, e);
        setSelectedBrand(brand);
    };

    const applyFilterAndRedirectToListingPage = ({query, options}) => {
        redirectToListingPageByQuery({ query, options });

    };

    return (
        <React.Fragment>
            <div className="customContainer" styleName={"styles.ourServices"}>
                <div styleName={"styles.servicesWrap"}>
                    {
                        isServicesLoading ?
                            <div className="shimmer" styleName={"styles.serviceSkeleton"} />
                            :
                            <div styleName={"styles.servicesList"}>
                                <AllServices serviceList={getServicesData()} onClickServiceTab={onClickServiceTab} />
                            </div>
                    }

                    <div styleName={"styles.searchWrap"}>
                        <FindDreamCarsV2
                            applyFilterAndRedirectToListingPage={applyFilterAndRedirectToListingPage}
                            homePage={true}
                            showAnimation={showAnimation}
                            platformLabel={platformLabel}
                        // dimensionObject={dimensionObject}
                        />
                    </div>

                    <div styleName={"styles.brandWrap"}>
                        {
                            popularBrandsList && !!popularBrandsList.length &&
                            <ul styleName={"styles.brandList"} >
                                {
                                    (popularBrandsList || []).map((popularBrand, idx) => {
                                        const { icon, slug, title } = popularBrand || {};
                                        return (
                                            icon && <li key={idx}>
                                                <SuperLogoCard
                                                    href={`${appUrl()}/${slug}`}
                                                    image={icon}
                                                    onClick={(e) => handleBrandSelect(popularBrand, e)}
                                                    altText={title} />
                                            </li>);
                                    })
                                }
                            </ul>

                        }
                        <div styleName={"styles.buttonWrap"}>
                            <Button label="View all cars" variant="primary" onClick={onClickViewAllCars} />
                        </div>
                    </div>

                </div>

                {
                    websiteAdvertisementBannerData &&
                 <div styleName="styles.web_ad_banner">
                     <GenericBrandBanner config={websiteAds} type={bannerType} pageType="super-web" position = "top" source = "desktop" />
                 </div>
                }
            </div>
            {
                modalServiceList && !!modalServiceList.length &&
                <AllServicesPopup
                    serviceList={modalServiceList}
                    title={sectionTitle}
                    onClose={onCloseServiceModal}
                    onClickServiceTab={onClickServiceTab}
                />
            }
        </React.Fragment>
    );
};

SuperWebServicesSection.propTypes = {
    superApp: PropTypes.object,
    sectionKey: PropTypes.string,
    popularBrands: PropTypes.array,
    appliedOptions: PropTypes.object,
    pincode: PropTypes.string,
    isCTAClickedWithoutPincode: PropTypes.object,
    cities: PropTypes.object,
    history: PropTypes.object,
    onClickCustomHandler: PropTypes.func,
    onClickViewAllCars: PropTypes.func,
    redirectToListingPageByQuery: PropTypes.func,
    toggleLocationPickerConnect: PropTypes.func,
    locationPageTypeConnect: PropTypes.func,
    setIsCTAClickedWithoutPincodeConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    showAnimation: PropTypes.bool,
    platformLabel: PropTypes.string,
    websiteAdvertisementBannerData: PropTypes.bool,
    statsigDynamicConfigData: PropTypes.object
};

export default SuperWebServicesSection;

