/* eslint-disable max-statements */
import { API_PATH_ALTERNATE } from "../constants/api-constants";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const {
    CJ_DETAILS,
    VIRTUAL_NUMBER,
    SELLER_REVIEWS,
    DIY_APPOINTMENT,
    CANCEL_DIY_APPOINTMENT,
    CONSUMER_SERVICE_SEND_OTP,
    CONSUMER_SERVICE_VERIFY_OTP,
    DIY_UPLOAD_DOCUMENTS,
    PURCHASE_REQUEST,
    DIY_BANK_VERIFY,
    IFSC_DETAILS,
    PRE_INSPECTION_DOC_UPLOAD,
    GEO_STORE_SLOT,
    GET_CONSULTATION_SLOTS,
    CART,
    ORDER,
    SELF_NEGO_PRICE,
    RESTART_SELF_NEGO_AUCTION,
    SAVE_DNP_DECISION,
    PRODUCT_CAR_WASH,
    RESTART_AUCTION,
    SELF_NEGO,
    REPORT_DETAILS,
    GEO_DETAILS,
    CAR_SERVICE_HISTORY_PRICE,
    GET_PRE_INS_DOC
} = API_PATH_ALTERNATE;

const carConsumerGatewayApi = (api) => {

    const fetchCjDetails = (appointmentId) => {
        return api.get(`${CJ_DETAILS}/${appointmentId}`);
    };

    const fetchVirtualNumber = (token) => {
        return api.get(`${VIRTUAL_NUMBER}/${token}/virtual-number`);
    };

    const fetchVirtualNumberWithAppointmentId = (appointmentId) => {
        return api.get(`/api/v1/appointment/${appointmentId}/virtual-number`);
    };

    const getSellerReviews = () => {
        return api.get(`${SELLER_REVIEWS}`);
    };
    const sendOtp = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}${CONSUMER_SERVICE_SEND_OTP}`);
    };

    const verifyOtp = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${CONSUMER_SERVICE_VERIFY_OTP}`, payload);
    };

    const cancelAppoinntment = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${CANCEL_DIY_APPOINTMENT}`, payload);
    };

    const uploadDocument = (token, params) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${DIY_UPLOAD_DOCUMENTS}`, params, {
            headers: {
                "content-type": "multipart/form-data;"
            }
        });
    };

    const purchaseRequest = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}${PURCHASE_REQUEST}`);
    };

    const diyBankVerify = (token, params) => {
        return api.post(`/api/v2/appointment/token/${token}${DIY_BANK_VERIFY}`, params);
    };

    const fetchInspectionDetails = (token) => {
        return api.get(`/api/v5/appointment/token/${token}`);
    };

    const fetchCarDetails = (appointmentId) => {
        return api.get(`/api/v1/appointment/${appointmentId}?sellOnline=true`);
    };

    const priceBreakup = (token, parameter) => {
        return api.get(`/api/v2/appointment/token/${token}/price/breakup${parameter ? `?expectedPrice=${parameter}` : ``}`);
    };

    const fetchInspectionDetailsViaLeadToken = (token) => {
        return api.get(`/api/v5/lead/appointment/token/${token}`);
    };

    const cafOtpVerify = (payload) => {
        return api.post(`api/v1/appointment/${payload.appointmentId}/document/otp/verify`, payload);
    };

    const initiatePRProcess = (token, payload) => {
        return api.post(`api/v2/appointment/token/${token}/initiate-purchase-request-process`, payload);
    };

    const createPurchaseRequest = (token) => {
        return api.post(`api/v1/appointment/token/${token}/purchase-request`, {});
    };

    const createPurchaseRequestV2 = (token, payload) => {
        return api.post(`api/v2/appointment/token/${token}/purchase-request`, payload);
    };

    const fetchRequiredDocumentList = (appId) => {
        return api.get(`api/v2/appointment/${appId}/documents`);
    };

    const uploadDocumentToDMS = (params, headerData) => {
        return api.post(`api/v2/seller/documents`, params, {
            headers: {
                "content-type": "multipart/form-data;",
                "jti": headerData.jti,
                "Authorization": `BEARER ${headerData.accessToken}`,
                "utid": headerData.utid
            }
        });
    };

    const dmsLogin = (appId) => {
        return api.post(`seller/dms/auth`, {
            appointmentId: appId,
            client: "diy-mweb",
            department: "RETAIL"
        });
    };

    const cancelPickup = (token, payload) => {
        return api.post(`api/v1/token/${token}/pickup/cancel`, payload);
    };

    const getPickupSlot = (token) => {
        return api.get(`api/v1/token/${token}/pickup/slots`, { parse: true });
    };

    const getPickupDetail = (token) => {
        return api.get(`api/v1/token/${token}/pickup`);
    };

    const schedulePickup = (token, payload) => {
        return api.post(`api/v1/token/${token}/pickup`, payload);
    };

    const pickupReschedule = (token, payload) => {
        return api.post(`api/v1/token/${token}/pickup/reschedule`, payload);
    };

    const getSlots = (token) => {
        return api.get(`api/v1/appointment/token/${token}/pickup/slots`);
    };

    const dowloadConsentLetter = (appointmentId)  => {
        return api.get(`document/download/consent-letter/${appointmentId}`);
    };

    const getCampaginPrice = (appointmentId)  => {
        return api.get(`pll/appointment/${appointmentId}/price`);
    };

    const postUserResponse = (token, payload)  => {
        return api.post(`pll/appointment/lead/token/${token}/seller-intent`, payload);
    };

    const getPickupDocuments = (appointmentId)  => {
        return api.get(`api/v1/runner/appointment/${appointmentId}/pickup/docket`);
    };

    const submitForQc = (appointmentId, payload) => {
        return api.post(`api/v1/runner/appointment/${appointmentId}/pickup/docket/verify`, payload);
    };

    const cancelDriverPickup = (appointmentId, payload) => {
        return api.post(`api/v1/runner/appointment/${appointmentId}/pickup/cancel`, payload);
    };

    const proceedWithPickup = (appointmentId, payload) => {
        return api.post(`api/v1/runner/appointment/${appointmentId}/pickup/docket/proceed`, payload);
    };

    const pickupDocument = (token) => {
        return api.get(`api/v1/token/${token}/pickup/docket`);
    };
    const verifyEkycDocument = (token, data) => {
        return api.post(`/api/v1/appointment/token/${token}/document/verify`, data);
    };

    const sellerReceipt = (token) => {
        return api.get(`/api/v1/appointment/token/${token}/seller-receipt`);
    };

    const diyPaymentApprovalProcess = (token, loanStatus = "PENDING") => {
        return api.get(`/api/v2/appointment/token/${token}/payment-approval-process?loanStatus=${loanStatus}`);
    };

    const fetchTokenProcessedDetails = (token, paymentType) => {
        const type = paymentType ? `?paymentType=${paymentType}` : "";
        const url = `${DIY_APPOINTMENT}/${token}/payment/transactions${type}`;
        return api.get(url);
    };

    const fetchIfscDetails = (payload) => {
        return api.post(IFSC_DETAILS, payload);
    };

    const savePriceView = (token, payload = {}) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/price/view`, payload);
    };

    const uploadDocumentsPreInspection = (appointmentId, params) => {
        return api.patch(`${PRE_INSPECTION_DOC_UPLOAD}/${appointmentId}`, params, {
            headers: {
                "content-type": "multipart/form-data;"
            }
        });
    };

    const fetchUploadedDocumentsPreInspection = (token) => {
        return api.get(`${GET_PRE_INS_DOC}/${token}`);
    };

    const fetchGeoSlots = (params) => {
        return api.get(GEO_STORE_SLOT, { params, parse: true });
    };

    const getConsultationSlots = () => {
        return api.get(GET_CONSULTATION_SLOTS);
    };

    const getAuctionDetails = (token) => {
        return api.get(`/api/v1/appointment/token/${token}/price`);
    };

    const getChallanDetails = (appointmentId) => {
        return api.get(`/api/v1/${appointmentId}/challans`);
    };

    const getPurchaseRequestDetails = (token) => {
        return api.get(`/api/v1/appointment/token/${token}/purchase-request`);
    };

    const addToCart = ({payload, authToken}) => {
        return api.post(CART, payload, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const getCartDetails = ({authToken}) => {
        return api.get(CART, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const removeFromCart = ({productUniqueIdentifier, authToken}) => {
        return api.delete(`${CART}/${productUniqueIdentifier}`, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const emptyCart = ({authToken}) => {
        return api.delete(CART, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const createOrder = ({
        payload = {},
        authToken = "",
        gaId = "",
        isDesktop = false,
        isWebview = false,
        isChallan = false
    }) => {
        return api.post(`${ORDER}${isChallan ? `?deviceType=${isDesktop ? "web" : "m-web"}&gaId=${gaId}&isWebView=${isWebview}` : ""}`, payload, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`,
                source: isDesktop ? "web" : "m-web"
            }
        });
    };

    const getOrderDetails = ({authToken, orderNumber}) => {
        return api.get(`${ORDER}/by-number/${orderNumber}`, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const getOrderList = ({authToken}) => {
        return api.get(`/api/v3/order/order-list`, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const getSelfNegoOptimalPrice = (token) => {
        return api.get(`/api/v2/appointment/token/${token}${SELF_NEGO_PRICE}`);
    };

    const restartAuction = (token, payload) => { //restart OCB
        return api.post(`/api/v2/appointment/token/${token}${RESTART_SELF_NEGO_AUCTION}`, payload);
    };

    const saveDnpDecision = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${SAVE_DNP_DECISION}`, payload);
    };

    const getCarWashPriceDetails = () => {
        return api.get(`${PRODUCT_CAR_WASH}/`);
    };

    const resetAuction = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${RESTART_AUCTION}`, payload);
    };

    const createNewPurchaseRequest = (token, payload) => {
        return api.post(`/api/v3/appointment/token/${token}/purchase-request`, payload);
    };

    const getDsiEligibility = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}/dsi-eligibility`);
    };

    const getDiyIntent = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/purchase-intent`, payload);
    };

    const submitCibilConsent = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/credit-report-consent`, payload);
    };

    const submitCibilNumber = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/credit-report-phone`, payload);
    };

    const verifyDocumentUpload = (token, payload, version = "v1") => {
        return api.post(`/api/${version}/appointment/token/${token}/document/upload/verify`, payload);
    };

    const saveSelfNegoExpectedPrice = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${SELF_NEGO}/selected-price`, payload);
    };

    const getSelfNegoKycDetails = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}${SELF_NEGO}/kyc`);
    };

    const fetchServiceHistoryAmount = (params) => {
        return api.get(CAR_SERVICE_HISTORY_PRICE, { params });
    };

    const submitBankMisMatchReason = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/bank-verify/mismatch-confirm`, payload);
    };

    const fetchReportDetails = (token) => {
        return api.get(`${REPORT_DETAILS}/${token}`);
    };

    const fetchGeoDetails = (params) => {
        return api.get(GEO_DETAILS, { params });
    };

    const getSimilarCarCount = (token) => {
        return api.get(`/api/v1/appointment/token/${token}/similar-cars-sold`);
    };

    const submitRaCallback = (token) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/request-ra-callback`);
    };

    const startDirectOcbAuction = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}${API_PATH_ALTERNATE.START_DIRECT_OCB_AUCTION}`, payload);
    };

    const getDirectOcbOptimalPrice = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}/direct-ocb-price`);
    };

    const getImpressionsCount = (token) => {
        return api.get(`/api/v2/appointment/token/${token}/auction/impression`);
    };
    const fetchLoanDetails = (token) => {
        return api.get(`/api/v1/appointment/token/${token}/loan-details`);
    };

    const submitLoanDetails = (token, payload) => {
        return api.post(`/api/v1/appointment/token/${token}/loan-details`, payload);
    };

    const getLeadMonDetails = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}/lead-mon/details`);
    };

    const submitLeadMonTransactionRequest = (token, payload) => {
        return api.post(`${DIY_APPOINTMENT}/${token}/lead-mon/transact-intent`, payload);
    };

    const getFranchiseDetails = (appId) => {
        return api.get(`/lead-monetization/api/franchise/price-discovery?appointmentId=${appId}`);
    };

    const getOrderByCategory = ({authToken, filterCriteria}) => {
        return api.post(`${ORDER}/search/order-list`, { filterCriteria }, {
            headers: {
                pvtAuthorization: authToken
            }
        });
    };

    const sendFrachiseDetail = (payload) => {
        return api.post(`/lead-monetization/api/franchise/price-acceptance`, payload);
    };

    const getCarHealth = (token) => {
        return api.get(`/api/v1/appointment/token/${token}/inspection-data`);
    };

    const getReportUrl = (reportToken) => {
        return api.get(`/report/details/${reportToken}`);
    };

    const stopAuction = (token) => {
        return api.post(`api/v1/appointment/token/${token}/stop-ocb`);
    };

    const sendUserSecondaryPhone = (token, payload) => {
        return api.put(`${DIY_APPOINTMENT}/${token}/user-profile`, payload);
    };

    const geFinalPayments = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}/payments`);
    };

    const getPartyPeshiVideo = (token) => {
        return api.get(`${DIY_APPOINTMENT}/${token}/party-peshi-video`);
    };

    return {
        getSlots,
        sendOtp,
        verifyOtp,
        getPickupSlot,
        fetchGeoDetails,
        getPickupDetail,
        schedulePickup,
        cafOtpVerify,
        postUserResponse,
        getCampaginPrice,
        fetchCjDetails,
        fetchVirtualNumber,
        getSellerReviews,
        cancelAppoinntment,
        uploadDocument,
        purchaseRequest,
        diyBankVerify,
        fetchInspectionDetails,
        fetchCarDetails,
        fetchInspectionDetailsViaLeadToken,
        priceBreakup,
        initiatePRProcess,
        createPurchaseRequest,
        fetchRequiredDocumentList,
        uploadDocumentToDMS,
        dmsLogin,
        cancelPickup,
        pickupReschedule,
        dowloadConsentLetter,
        getPickupDocuments,
        submitForQc,
        proceedWithPickup,
        cancelDriverPickup,
        fetchVirtualNumberWithAppointmentId,
        pickupDocument,
        verifyEkycDocument,
        sellerReceipt,
        diyPaymentApprovalProcess,
        fetchTokenProcessedDetails,
        fetchIfscDetails,
        savePriceView,
        createPurchaseRequestV2,
        uploadDocumentsPreInspection,
        fetchUploadedDocumentsPreInspection,
        fetchGeoSlots,
        getConsultationSlots,
        getAuctionDetails,
        getChallanDetails,
        getPurchaseRequestDetails,
        addToCart,
        getCartDetails,
        removeFromCart,
        emptyCart,
        createOrder,
        getOrderDetails,
        getOrderList,
        getSelfNegoOptimalPrice,
        restartAuction,
        saveDnpDecision,
        getCarWashPriceDetails,
        resetAuction,
        createNewPurchaseRequest,
        getDsiEligibility,
        getDiyIntent,
        submitCibilConsent,
        submitCibilNumber,
        verifyDocumentUpload,
        saveSelfNegoExpectedPrice,
        getSelfNegoKycDetails,
        fetchServiceHistoryAmount,
        submitBankMisMatchReason,
        fetchReportDetails,
        getSimilarCarCount,
        getDirectOcbOptimalPrice,
        submitRaCallback,
        startDirectOcbAuction,
        getImpressionsCount,
        fetchLoanDetails,
        submitLoanDetails,
        getLeadMonDetails,
        submitLeadMonTransactionRequest,
        getOrderByCategory,
        getFranchiseDetails,
        sendFrachiseDetail,
        getCarHealth,
        getReportUrl,
        stopAuction,
        sendUserSecondaryPhone,
        geFinalPayments,
        getPartyPeshiVideo
    };
};

export const carConsumerService = carConsumerGatewayApi(middlewareConfig(MIDDLEWARE_ENUMS.CAR_CONSUMER_GATEWAY));

