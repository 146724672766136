/* eslint-disable max-statements */
import { API_PATH_ALTERNATE } from "../constants/api-constants";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

// eslint-disable-next-line max-statements
const vehicleApi = (api) => {

    const {
        FAQ_LIST,
        GET_QUESTION_FOR_USER_INTENT,
        SIMILAR_CARS_SOLD,
        TESTIMONIAL,
        POPULAR_CITIES,
        RTO_DETAIL_BY_REG_NO,
        SCRAP_CAR_QUESTIONS,
        CHECK_GA_EXPERIMENT,
        GALLERY_IMAGES,
        RTO,
        GALLERY_VIDEO,
        MAKE,
        CITY_SEARCH,
        SEO_CONTENT
    } = API_PATH_ALTERNATE;

    const fetchDealersInCity = (cityId) => {
        return api.get(`/select-dealer/list/${cityId}`);
    };

    const createDealerLead = (params) => {
        return api.post("/select-dealer/lead", params);
    };

    const sendDealerLeadOtp = (token) => {
        return api.get(`select-dealer/send-otp/${token}`);
    };

    const verifyDealerLeadOtp = (token, otp) => {
        return api.get(`/select-dealer/otp-verify/${token}/${otp}`);
    };

    const vehicleNoRegistration = (registrationNo) => {
        return api.get(`vehicle-number/${registrationNo}`);
    };

    const getVehicleBoughtCount = (modelId) => {
        return api.get(`car-bought-count/${modelId}`);
    };

    const fetchFinancingDocuments = () => {
        return api.get(`/document/finance`);
    };

    const getSellOnlineQuestion = (payload) => {
        return api.get(`sell-online-question?version=${payload}`);
    };
    const saveOnlineQuestion = (payload) => {
        return api.post(`appointment/question/save`, payload);
    };
    const getAppointmentCancelReasons = () => {
        return api.get(`appointment/cancel/reasons`);
    };

    const fetchFaqs = (params) => {
        return api.get(FAQ_LIST, { params });
    };
    const getQuestionForUserIntent = (type) => {
        if (type) {
            return api.get(`${GET_QUESTION_FOR_USER_INTENT}?type=${type}`);
        } else {
            return api.get(`${GET_QUESTION_FOR_USER_INTENT}`);
        }
    };

    const fetchHomePageFAQs = () => {
        return api.get("faq-search?version=2", {});
    };

    const fetchMotivationalStories = () => {
        return api.get("api/v1/content?expand=b2c_tweets");
    };

    const getSimilarCarsSold = (params) => {
        return api.get(SIMILAR_CARS_SOLD, { params });
    };

    const fetchTestimonials = () => {
        return api.get(TESTIMONIAL);
    };

    const fetchPopularCities = (params) => {
        return api.get(`${POPULAR_CITIES}`, { params });
    };

    const getRtoDetailByRegNo = (registrationNo) => {
        return api.get(`${RTO_DETAIL_BY_REG_NO}/${registrationNo}`);
    };

    const getScrapCarQuestions = () => {
        return api.get(`${SCRAP_CAR_QUESTIONS}`);
    };

    const fetchTestimonialsByCategory = (category) => {
        return api.get(`${TESTIMONIAL}?category=${category}`);
    };
    const fetchStateList = () => {
        return api.get(`/get-states`);
    };
    const checkC2bGaExperiments = (payload) => {
        return api.post(CHECK_GA_EXPERIMENT, payload);
    };

    const getGalleryContent = (slug = "") => {
        return api.get(`${GALLERY_IMAGES}?slug=${slug}`);
    };

    const fetchRTODetails = (params) => {
        return api.get(`${RTO}`, { params });
    };

    const fetchModelImg = (modelId, params) => {
        return api.get(`v1/model/image/${modelId}${params ? `?${params}` : ""}`);
    };

    const getSeoContent = (params) => {
        return api.get(`${SEO_CONTENT}`, { params });
    };

    const fetchVideoGallery = (slug = "") => {
        return api.get(`${GALLERY_VIDEO}?slug=${slug}`);
    };

    const fetchModels = (makeId) => {
        return api.get(`${MAKE}/${makeId}`);
    };
    const searchCity = (searchText) => {
        return api.get(`${CITY_SEARCH}/${searchText}`);
    };

    return {
        fetchFaqs,
        fetchTestimonials,
        getSellOnlineQuestion,
        saveOnlineQuestion,
        fetchDealersInCity,
        createDealerLead,
        sendDealerLeadOtp,
        verifyDealerLeadOtp,
        vehicleNoRegistration,
        getVehicleBoughtCount,
        fetchFinancingDocuments,
        getQuestionForUserIntent,
        getAppointmentCancelReasons,
        fetchHomePageFAQs,
        fetchMotivationalStories,
        getSimilarCarsSold,
        fetchPopularCities,
        getRtoDetailByRegNo,
        getScrapCarQuestions,
        fetchTestimonialsByCategory,
        checkC2bGaExperiments,
        getGalleryContent,
        fetchRTODetails,
        fetchModelImg,
        fetchVideoGallery,
        fetchModels,
        getSeoContent,
        searchCity,
        fetchStateList
    };
};

export const VehicleService = vehicleApi(middlewareConfig(MIDDLEWARE_ENUMS.VEHICLE_BASE_CONST));
