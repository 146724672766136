import { reject } from "lodash";
import { findByKey } from "../../../utils/helpers/find-by-key";
import { updateCityLocationDetails } from "../../location/actions";
import Types from "./types";

const toggleLocationPicker = (status, autoFocusSearch = false) => ({
    type: Types.TOGGLE_LOCATION_PICKER,
    status,
    autoFocusSearch
});

const setLocationSelectionType = (locationType) => ({
    type: Types.LOCATION_SELECTETION_TYPE,
    locationType
});

const toggleLocationPickerV2 = (status) => ({
    type: Types.TOGGLE_LOCATION_PICKER_V2,
    status
});

const locationPageType = (pageType) => ({
    type: Types.LOCATION_PAGE_TYPE,
    pageType
});

const setIsCTAClickedWithoutPincode = (isCTAClickedWithoutPincode) => ({
    type: Types.IS_CTA_CLICKED_WITHOUT_PINCODE,
    isCTAClickedWithoutPincode
});

const updateUserLocation = (data) => (dispatch, getState) => {
    return new Promise((resolve) => {
        const {cityId} = data || {};
        const cityList = getState().config.cityList || [];
        const selectedUserCity = findByKey(cityList, "city_id", (cityId || "").toString());
        if (selectedUserCity) {
            resolve(selectedUserCity);
            dispatch(updateCityLocationDetails(selectedUserCity));
        } else {
            reject("Pincode not found");
        }
    });

};
export {
    toggleLocationPicker,
    toggleLocationPickerV2,
    setLocationSelectionType,
    locationPageType,
    setIsCTAClickedWithoutPincode,
    updateUserLocation
};
